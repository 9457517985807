$(function() {
  // WISHLIST
  if($('#wishlist').length) {
    $('body').addClass('body-wishlist');
  }

  // Botao voltar ao topo
  $('#voltar, .voltar').click(function(e){
    e.preventDefault();
    $("html, body").stop().animate({scrollTop: 0}, '500', 'swing');
    return false;
  });
});